// log the pageview with their URL
export const pageview = (url: any) => {
  // @ts-ignore
  window.gtag('config', process.env.GA_ID, {
    page_path: url,
  });
};

// log specific events happening.
// @ts-ignore
export const event = ({ action, params }) => {
  // @ts-ignore
  window.gtag('event', action, params);
};
