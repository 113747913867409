export const COLOR = {
  DARK: '#e16018',
  PRIMARY: '#fa7f3b',
  LIGHT: '#ffa979',
  HIGH_EMP: '#fde0cf',
  MED_EMP: '#fdeadf',
  LOW_EMP: '#fef4ee',
  SUCCESS: '#6ec36f',
  ERROR: '#C13F3F',
  ERROR_CONTAINER: '#f8dbd5',
  ERROR_CONTAINER_LIGHT: '#ffe1e1',
  BACKGROUND: '#fcfcfc',
  SURFACE: '#fefefe',
  BLACK: '#1f1f1f',
  DARK_GRAY: '#555555',
  GRAY: '#949494',
  LIGHT_GRAY: '#f4f4f4',
  DISABLED: 'rgba(0, 0, 0, 0.18)',
  ON_DISABLED: '#e8e8e8',
  DIVIDER_DARK: '#b3b3b3',
  DIVIDER_LIGHT: '#d0d0d0',
  OUTLINE: '#e1e1e1',
  LINEAR: 'linear-gradient(to top, #fa7f3b -27%, rgba(250, 127, 59, 0.12) 108%);',
  GRADIENT: 'linear-gradient(98deg, #fa7f3b -15%, #ffdfee 110%);',
  RADIENT: 'radial-gradient(circle at 111% 50%, #fa7f3b, rgba(250, 127, 59, 0.06) 57%);',
  KAKAO: '#FEE500',
  NAVER: '#03C75A'
};
