export const getSystemLang = (langArr: string[] | null | undefined) => {
  const arr = langArr || [];

  const isKo = arr.includes('ko');
  const isEn = arr.includes('en');
  const isJa = arr.includes('ja');
  const isCn = arr.includes('zh');
  const isEs = arr.includes('es');

  const koIndex = arr.indexOf('ko');
  const enIndex = arr.indexOf('en');
  const jaIndex = arr.indexOf('ja');
  const cnIndex = arr.indexOf('zh');
  const esIndex = arr.indexOf('es');

  let systemLang;

  // if idx is -1 (= lang doesn't exist in langArr, make idx into 500)
  const pushIndexToBack = (idx: number) => {
    return idx === -1 ? 1000 : idx;
  };

  if (isKo || isEn || isJa || isCn || isEs) {
    const indexArray = [
      { lang: 'ko', idx: pushIndexToBack(koIndex) },
      { lang: 'en', idx: pushIndexToBack(enIndex) },
      { lang: 'ja', idx: pushIndexToBack(jaIndex) },
      { lang: 'zh', idx: pushIndexToBack(cnIndex) },
      { lang: 'es', idx: pushIndexToBack(esIndex) },
    ];
    // get the index of the smallest langIndex
    const langIndex = indexArray.reduce((index, value, i, array) => (value.idx < array[index].idx ? i : index), 0);
    // set the systemLang to language that is prioritized.
    // if user is set to use chinese language as their primary language, function returns 'cn' instead of 'zh'
    systemLang = indexArray[langIndex].lang === 'zh' ? 'cn' : indexArray[langIndex].lang;
  } else {
    systemLang = 'en';
  }

  return systemLang;
};
