import baseStyled, { css, CSSProp } from 'styled-components';
import { COLOR } from './COLOR';
import { FONT_SIZE, MB_FONT_SIZE } from './FONT_SIZE';
import { RESPONSIVE_SIZE } from './RESPONSIVE_SIZE';
import { RADIUS } from './RADIUS';

interface Media {
  S: (...args: any) => CSSProp | undefined; // 0 ~ 719.9
  M: (...args: any) => CSSProp | undefined; // 720 ~ 1279.9
  ML: (...args: any) => CSSProp | undefined; // 720 ~
  L: (...args: any) => CSSProp | undefined; // 1280~
  SM: (...args: any) => CSSProp | undefined; // ~1280
}

const media: Media = {
  S: (args: any) => undefined,
  M: (args: any) => undefined,
  ML: (args: any) => undefined,
  L: (args: any) => undefined,
  SM: (args: any) => undefined,
};

Object.keys(media).reduce((acc: Media, label: string) => {
  switch (label) {
    case 'M':
      acc.M = (args: any) =>
        css`
          @media only screen and (min-width: ${RESPONSIVE_SIZE.S_UB}px) and (max-width: ${RESPONSIVE_SIZE.M_UB -
            0.1}px) {
            ${args}
          }
        `;
      break;
    case 'ML':
      acc.ML = (args: any) =>
        css`
          @media only screen and (min-width: ${RESPONSIVE_SIZE.S_UB}px) {
            ${args}
          }
        `;
      break;

    case 'S':
      acc.S = (args: any) =>
        css`
          @media only screen and (max-width: ${RESPONSIVE_SIZE.S_UB - 0.1}px) {
            ${args}
          }
        `;
      break;

    case 'L':
      acc.L = (args: any) =>
        css`
          @media only screen and (min-width: ${RESPONSIVE_SIZE.M_UB}px) {
            ${args}
          }
        `;
      break;

    case 'SM':
      acc.SM = (args: any) =>
        css`
          @media only screen and (max-width: ${RESPONSIVE_SIZE.M_UB - 0.1}px) {
            ${args}
          }
        `;
      break;

    default:
      break;
  }
  return acc;
}, media);

const theme = {
  media,
  COLOR,
  FONT_SIZE,
  MB_FONT_SIZE,
  RADIUS,
};

export type Theme = typeof theme;
export const styled = baseStyled;
export default theme;
