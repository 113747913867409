export const RESPONSIVE_SIZE: { S_UB: 720; M_UB: 1280 } = {
  /*
              720            1280
      ---------|---------------|----
              S_UB            M_UB
   */

  S_UB: 720, // mobile upper bound 1 (bigger) == tablet lower bound 1
  M_UB: 1280, // tablet upper bound == desktop lower bound
};
