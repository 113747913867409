import { createGlobalStyle } from 'styled-components';

interface GlobalStyleTypes {
  theme: {
    COLOR: any;
    FONT_SIZE: any;
    media: any;
  };
}

const GlobalStyle = createGlobalStyle<GlobalStyleTypes>`
  html[lang='ko'],
  body[lang='ko'],
  div[lang='ko'],
  p[lang='ko'],
  a[lang='ko'],
  input[lang='ko'],
  label[lang='ko'],
  textarea[lang='ko'] {
    font-family: 'Pretendard Variable', 'Pretendard JP Variable', sans-serif;
  }

  html[lang='en'],
  body[lang='en'],
  div[lang='en'],
  p[lang='en'],
  a[lang='en'],
  input[lang='en'],
  label[lang='en'],
  textarea[lang='en'] {
    font-family: 'Pretendard Variable', 'Pretendard JP Variable', sans-serif;
  }

  html[lang='ja'],
  body[lang='ja'],
  div[lang='ja'],
  p[lang='ja'],
  a[lang='ja'],
  input[lang='ja'],
  label[lang='ja'],
  textarea[lang='ja'] {
    font-family: 'Pretendard JP Variable', 'Pretendard Variable', sans-serif;
  }
  
  html[lang='ko'] {
    font-family: 'Pretendard Variable', 'Pretendard JP Variable', sans-serif;
  }

  html[lang='cn'],
  body[lang='cn'],
  div[lang='cn'],
  p[lang='cn'],
  a[lang='cn'],
  input[lang='cn'],
  label[lang='cn'],
  textarea[lang='cn'] {
    font-family: 'Noto Sans SC', 'Pretendard Variable', 'Pretendard JP Variable', Pretendard, -apple-system,
    BlinkMacSystemFont, 'Apple SD Gothic Neo', Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
  }
  
  button {
    font-family: 'Pretendard Variable', 'Pretendard JP Variable', sans-serif;
    &:lang(ko) {
      font-family: 'Pretendard Variable', 'Pretendard JP Variable', sans-serif;
    }
    &:lang(cn) {
      font-family: 'Noto Sans SC', 'Pretendard Variable', 'Pretendard JP Variable', Pretendard, -apple-system,
    BlinkMacSystemFont, 'Apple SD Gothic Neo', Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
    }
  }

  .S_only {
    ${({ theme }) =>
    theme.media.ML(`
      display: none !important;
    `)}
  }

  // 720~1024
  .M_only {
    ${({ theme }) =>
    theme.media.S(`
      display: none !important;
    `)}

    ${({ theme }) =>
    theme.media.L(`
    display: none !important;
  `)}
  }

  // 1280~
  .L_only {
    ${({ theme }) =>
    theme.media.SM(`
      display: none !important;
    `)}
  }

  // ~1280
  .SM_only {
    ${({ theme }) =>
    theme.media.L(`
      display: none !important;
    `)}
  }

  .SL_only {
    ${({ theme }) =>
    theme.media.M(`
      display: none !important;
    `)}
  }
    
  .ML_only {
    ${({ theme }) =>
    theme.media.S(`
      display: none !important;
    `)}
  }
`;

export default GlobalStyle;
