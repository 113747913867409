export const FONT_SIZE: any = {
  display1: 38,
  display2: 25,
  heading: 20,
  title: 16,
  body: 15,
  caption: 13,
  sug: {
    body: 18,
    caption: 16,
  },
  // new font size constant
  headingL: 40,
  headingM: 32,
  headingS: 24,
  subhead: 20,
  bodyL: 18,
  bodyM: 16,
  bodyS: 14,
  captionL: 12,
  captionM: 10,
  captionS: 8,
};
export const MB_FONT_SIZE: any = {
  display1: 25,
  display2: 20,
  heading: 16,
  body: 15,
  caption: 13,
};
